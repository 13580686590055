@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #323231;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .header {
    @apply text-center text-5xl
  }
}

.header {
  background-color:#f9f9f9;
  position: fixed;
  width: 100%;
  top: 0;
  .header-container {
    width: 80%;
    padding: 20px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 150px;
    }
    .links {
      ul li {
        display: inline-block;
        font-size: 30px;
        font-weight: 300;
      }
      ul li:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

p {
  margin-bottom: 20px;
}

.main-container {
  margin: 0 auto;
}

.block-about, .block-portfolio {
  background-color: white;
}

.block-services, .block-contact {
  background-color: #f1f0f0;
}

.main-section#about {
  padding-top: 180px;
}

.main-section#about, .main-section#portfolio {
  background-color: white;
  width: 80%;
  margin: 0 auto;
}

.main-section#services, .main-section#contact {
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  margin: 0 auto;
}

.main-section {
  display: flex;
  flex-direction: row;
  padding: 50px 0;
  margin: 50px 0;
}

.main-section .intro-section {
  width: 50%;
  text-align: center;
  font-size: 4rem;
}

.main-section  .detail-section {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .main-section, .main-section#services, .main-section#contact {
    padding: 30px 0;
    margin: 30px 0;
    flex-direction: column;
  
    .intro-section {
      width: 100%;
      font-size: 2rem;
      padding: 0;
    }
  
    .detail-section {
      width: 100%;
      padding: 0;
    }
  }

  .m-margin-b { // mobile margin bottom
    margin-bottom: 30px;
  }
}

#services ul {
  list-style-type: disc;
  margin-bottom: 30px;
}

.detail-section a {
  text-decoration: underline;
  color: #3a718a;
}

.detail-section p, .detail-section ul {
  font-size: 1.2rem;
}

.social-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.social-logo img {
  width: 30px;
}

.pic-container {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  img {
    width: 160px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid black;
    padding: 2px;
  }
}

.indent {
  padding-left: 20px;
  li {
    margin-bottom: 5px;
  }
}